import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactNotifications from 'react-notifications-component';
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player';
import actions from '../../redux/actions';
import './styles.css';
import CustomVideoPlayer from '../../components/players/video/CustomVideoPlayer';
const ViewRecording = (props) => {
  const location = useLocation();
  const [recordingUrl, setRecordingUrl] = useState(location.state?.recordingUrl || localStorage.getItem("recordingUrl") || '');
  const [lectureId, setLectureId] = useState(location.state?.lecture_id || localStorage.getItem("lectureId") || '');

  useEffect(() => {
    const url = window.location.pathname;
    const getid = url.substring(url.lastIndexOf('/') + 1);
    console.log("getid", getid);
    console.log("recordingUrl", location.state?.recordingUrl);

    document.addEventListener('contextmenu', (e) => e.preventDefault());

    const ctrlShiftKey = (e, keyCode) => e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);

    document.onkeydown = (e) => {
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, 'I') ||
        ctrlShiftKey(e, 'J') ||
        ctrlShiftKey(e, 'C') ||
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
      ) return false;
    };

    return () => {
      document.removeEventListener('contextmenu', (e) => e.preventDefault());
    }
  }, [recordingUrl]);

  return (
    <div className="viewRecordingWrapper">
      <Helmet>
        <title>View Recording</title>
      </Helmet>
      <ReactNotifications />

      <div className='app-body'>
        <div className='app-div'>
          <button
            style={{ marginBottom: '10px' }}
            onClick={() => window.history.back()}
          >
            Back
          </button>

          {recordingUrl ? (
            <>
              {recordingUrl.includes('.mp4') ? (
                // <ReactPlayer
                //   config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                //   url={recordingUrl}
                //   controls={true}
                //   width="100%"
                //   height="500px"
                //   className="react-player"
                //   autoplay={true}
                // />
                <CustomVideoPlayer url={recordingUrl}
                lecture_id={location.state?.lecture_id}
                  onError={() => {
                  }}
                />
              ) : (
                <iframe allowfullscreen="1" src={recordingUrl}></iframe>
              )}
            </>
          ) : (
            <div className='unauthorizedAccessDialog'>
              <div className='unauthorizedAccessContainer'>
                Unauthorized access <br /> You do not have the required permissions.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => ({
  loading: state.commonReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getStudentLectureList: () => dispatch(actions.getStudentLectureList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecording);